<template>
    <!-- About-->
        <div id="timeline">
            <div class="container topmargin">
                <div class="text-center">
                    <!-- <h1 class="">Aan de slag</h1> -->
                    <h1 class="display-6 pb-5">Hoe gaat dat in zijn werk?</h1>
                </div>
                <ul class="timeline">
                    <li>
                        <div class="timeline-image">
                          <!-- <img class="rounded-circle img-fluid" src="small/favthings.jpg" alt="..." /> -->
                          <Picture path="small/favthings" classb="rounded-circle img-fluid"/>
                        </div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4>Plaats de opdracht</h4>
                                <h6 class="subheading">Uiterlijk 4 weken voor de geboorte</h6>
                            </div>
                            <div class="timeline-body"><p class="text-muted">Kies je thema, lever je persoonlijke foto’s aan en plaats de opdracht!</p></div>
                        </div>
                    </li>
                    <li class="timeline-inverted">
                        <div class="timeline-image">
                          <!-- <img class="rounded-circle img-fluid" src="small/designer4.jpg" alt="..." /> -->
                          <Picture path="small/designer4" classb="rounded-circle img-fluid"/>
                        </div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4>Ontwerp</h4>
                                <h6 class="subheading">Binnen 3 weken</h6>
                            </div>
                            <div class="timeline-body"><p class="text-muted">Krijg je jouw persoonlijke ontwerp in PDF formaat.</p></div>
                        </div>
                    </li>
                    <li>
                        <div class="timeline-image">
                          <!-- <img class="rounded-circle img-fluid" src="small/jake.jpeg" alt="..." /> -->
                          <Picture path="small/jake" classb="rounded-circle img-fluid"/>
                        </div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4>De geboorte</h4>
                                <h6 class="subheading">Mail ons</h6>
                            </div>
                            <div class="timeline-body"><p class="text-muted">Geniet van dit kleine wondertje! Zodra je er klaar voor bent: mail ons de laatste details die je op het kaartje wilt.</p></div>
                        </div>
                    </li>
                    <li class="timeline-inverted">
                        <div class="timeline-image">
                          <!-- <img class="rounded-circle img-fluid" src="small/pak1.jpg" alt="..." /> -->
                          <Picture path="small/pak1" classb="rounded-circle img-fluid"/>
                        </div>
                        <div class="timeline-panel">
                            <div class="timeline-heading">
                                <h4>Tadaa!</h4>
                                <h6 class="subheading">Binnen 2-4 werkdagen</h6>
                            </div>
                            <div class="timeline-body"><p class="text-muted">Ontvang je een pakket met daarin jouw persoonlijke geboortekaartjes!</p></div>
                        </div>
                    </li>
                    <!-- <li class="timeline-inverted">
                        <div class="timeline-image">
                            <h4>
                                Uniek
                                <br />
                                Snel
                                <br />
                                Makkelijk!
                            </h4>
                        </div>
                    </li> -->
                </ul>
            </div>
        </div>
</template>

<script>
import Picture from './Picture.vue'

export default {
    name: 'Timeline',
    components:{Picture},
    data(){
        return {}
    }
}
</script>

<style scoped>

.timeline {
  position: relative;
  padding: 0;
  list-style: none;
}
.timeline:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40px;
  width: 2px;
  margin-left: -1.5px;
  content: "";
  background-color: #e9ecef;
}
.timeline > li {
  position: relative;
  min-height: 50px;
  margin-bottom: 50px;
}
.timeline > li:after, .timeline > li:before {
  display: table;
  content: " ";
}
.timeline > li:after {
  clear: both;
}
.timeline > li .timeline-panel {
  position: relative;
  float: right;
  width: 100%;
  padding: 0 20px 0 100px;
  text-align: left;
}
.timeline > li .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}
.timeline > li .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}
.timeline > li .timeline-image {
  position: absolute;
  z-index: 100;
  left: 0;
  width: 80px;
  height: 80px;
  margin-left: 0;
  text-align: center;
  color: white;
  border: 7px solid #e9ecef;
  border-radius: 100%;
  background-color: #ffc800;
}
.timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
  font-size: 10px;
  line-height: 14px;
  margin-top: 12px;
}
.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
  padding: 0 20px 0 100px;
  text-align: left;
}
.timeline > li.timeline-inverted > .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}
.timeline > li.timeline-inverted > .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}
.timeline > li:last-child {
  margin-bottom: 0;
}
.timeline .timeline-heading h4, .timeline .timeline-heading .h4 {
  margin-top: 0;
  color: inherit;
}
.timeline .timeline-heading h4.subheading, .timeline .timeline-heading .subheading.h4 {
  text-transform: none;
}
.timeline .timeline-body > ul,
.timeline .timeline-body > p {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .timeline:before {
    left: 50%;
  }
  .timeline > li {
    min-height: 100px;
    margin-bottom: 100px;
  }
  .timeline > li .timeline-panel {
    float: left;
    width: 41%;
    padding: 0 20px 20px 30px;
    text-align: right;
  }
  .timeline > li .timeline-image {
    left: 50%;
    width: 100px;
    height: 100px;
    margin-left: -50px;
  }
  .timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
    font-size: 13px;
    line-height: 18px;
    margin-top: 16px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    float: right;
    padding: 0 30px 20px 20px;
    text-align: left;
  }
}
@media (min-width: 992px) {
  .timeline > li {
    min-height: 150px;
  }
  .timeline > li .timeline-panel {
    padding: 0 20px 20px;
  }
  .timeline > li .timeline-image {
    width: 150px;
    height: 150px;
    margin-left: -75px;
  }
  .timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
    font-size: 18px;
    line-height: 26px;
    margin-top: 30px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 20px 20px;
  }
}
@media (min-width: 1200px) {
  .timeline > li {
    min-height: 170px;
  }
  .timeline > li .timeline-panel {
    padding: 0 20px 20px 100px;
  }
  .timeline > li .timeline-image {
    width: 170px;
    height: 170px;
    margin-left: -85px;
  }
  .timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
    margin-top: 40px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 100px 20px 20px;
  }
}

.topmargin{
  margin-top: 60px;
}

</style>