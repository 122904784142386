<template>
    <picture id='Picture'>
        <source :srcset="path + '.avif'" type="image/avif" :alt="path" :width="width" :class="classb" >
        <source :srcset="path + '.jpg'" type="image/jpeg" :alt="path" :width="width" :class="classb">
        <img :src="path + '.jpg'" :alt="path" :width="width" :class="classb" >
    </picture>
</template>

<script>
export default {
    name:'Picture',
    props:['path','width','classb']
}
</script>