<template>
  <div id="app">
    <Navbar/>
    <!-- <Home
      :window="window"
    /> -->
    <router-view 
      :window="window"  
    />
    <Footer/>
  </div>
</template>

<script>

import Navbar from './components/Navbar.vue'
// import Home from './components/Home.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    // Home,
    Footer
  },
  data(){
    return{
      window: {
        width: 0,
        height: 0
      }
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed() {
      window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    }
  }
}

</script>

<style>

</style>
