<template>
    <div id='Gallery' :style="bgc(bgColor)">
        
        <!-- <h1 class="display-6 text-center topmargin">{{title}}</h1> -->
        <div class="container-md">
            <div class="row">
                
                <div v-bind:key="photo" v-for="photo in photoArray.front"
                    class="
                    col-xl-4 col-md-6">
                    <!-- <img v-bind:src="photo" v-bind:alt="photo" class="img-fluid" > -->
                    <Picture :path="photo" classb="img-fluid"/>

                    <!-- <div class='gy-4'>sad</div> -->
                    <div class="spacer"></div>
                </div>
            </div>

            
            <button 
                class="btn btn-primary" 
                type="button" 
                data-bs-toggle="collapse" 
                data-bs-target="#collapseExample" 
                aria-expanded="false" 
                aria-controls="collapseExample">
                Meer...
            </button>
            <div class="spacer"></div>
            <div class="collapse" id="collapseExample">
                <Carousel
                        :photoArray="photoArray"
                        :uid="'Voorbeelden'"
                        :window="window"
                    />
                <div class="spacer"></div>
            </div>
            

        </div>


    </div>
</template>

<script>
import Carousel from './Carousel.vue'
import Picture from './Picture.vue'

export default {
    name: 'Gallery',
    props: ['photoArray','bgColor','window'],
    components: {
        Carousel,
        Picture
    },
    data(){
        return {
            // frontGallery : [],
            // backGallery : []
        }
    },
    created(){
        // this.frontGallery = this.photoArray.slice(0,3)
        // this.backGallery = this.photoArray.slice(3)
    },
    methods:{
        bgc(){
            // console.log('gallery bgc')
            if (this.bgColor=='blue'){
                return {'background-color': 'rgba(190, 221, 228, 0.486)'}
            } else if (this.bgColor=='yellow'){
                return {'background-color': 'rgba(200, 160, 100, 0.3)'}
            } else if (this.bgColor=='white'){
                return {'background-color': 'rgba(0, 0, 0, 0)'}
            }  
        }
    }
}
</script>

<style>
.topmargin{
  padding-top: 0px;
}

.spacer{
    padding-bottom: 25px;
}

/* .collapse{
    padding-bottom: 25px;
} */



</style>