<template>
    <div id="Home">
        <ScrollHero
            :title="hero1.title"
            :text="hero1.text"
            :image="hero1.image"
        />
        <PhotoExplainerV2 class="pt-4 green"
          :title="photo3.title"
          :text="photo3.text"
          :photoLeft="true"
          :photo="photo3.photo"
        /> 


        <PhotoExplainer
          :title="photo2.title"
          :text="photo2.text"
          :photoLeft="false"
          :photo="photo2.photo"
        />

        <PhotoExplainerV2 class="pb-4 blue"
          :title="photo1.title"
          :text="photo1.text"
          :photoLeft="true"
          :photo="photo1.photo"
          
        />


        <Title
          :title="carousel.title"
          :subtitle="carousel.subtitle"
          :bgColor="'white'"
        />
        
        <div class="container-xl">
          <Carousel
            :photoArray="carousel.photoArray"
            :uid="'Kaartjes'"
            :window="window"
          />
        </div>

        <Title
          :title="gallery.title"
          :subtitle="gallery.subtitle"
          :bgColor="'yellow'"
        />

        <Gallery
          :photoArray="gallery.photoArray"
          :bgColor="'yellow'"
          :window="window"
        />      
        
        <Timeline/>

        <Title
          :title="pricing.title"
          :subtitle="pricing.subtitle"
          :bgColor="'white'"
        />

        <Pricing/>
        
      </div>
    <!-- </div> -->
</template>



<script>
import ScrollHero from './ScrollHeroV2.vue'
import Pricing from './Pricing.vue'
import PhotoExplainer from './PhotoExplainer.vue'
import PhotoExplainerV2 from './PhotoExplainerV2.vue'
import Timeline from './Timeline.vue'
import Gallery from './Gallery.vue'
import Carousel from './Carousel.vue'
import Title from './Title.vue'

export default {
    name: 'Home',
    props:['window'],
    components: {
        ScrollHero,
        Pricing,
        PhotoExplainer,
        PhotoExplainerV2,
        Timeline,
        Gallery,
        Carousel,
        Title
    },
    data(){
        return {
          hero1:{
            title:'Geboortekaartjes op maat',
            text: 'Een baby is meer dan een naam. Het is een wonder van jullie samen. Wonderpost brengt die boodschap over.',
          },
          photo1:{
            title: 'Maak het persoonlijk',
            text: "Op basis van items, huisdieren, broertjes/zusjes maakt wonderpost persoonlijke kaartjes. Upload 5-10 foto's van dingen die iets over jullie zeggen.",
            photo: 'small/favthings2'
            //Je bepaalt dus zelf wat er op het kaartje komt. Alles kan.
          },
          photo2:{
            title: 'Kies een voorgrond',
            text: "Kies uit de verschillende voorgronden een ontwerp. Dit is de eye-catcher van het kaartje die we helemaal voor jullie gaan personaliseren.",
            photo: 'small/Vlieger_solo.png'
          },
          photo3:{
            title: 'Kies een achtergrond',
            text: "Kies uit een van de vele designs een achtergrond die bij jullie past. Dit wordt de basis van jullie kaartje.",
            photo: 'small/Heuvels_solo-01'
            //En bepaal welke teksten je in het kaartje wilt. Kies bijvoorbeeld voor een mooie quote van een bekend persoon of een klein gedichtje. 
          },
          gallery:{
            photoArray:{
              front:[
                'small/sq/Robin',
                'small/sq/Thijs',
                'small/sq/Mees',
              ],
              small:[
                'small/sq/Oscar',
                'small/sq/Jake',
                'small/sq/Sophie',
                'small/sq/Dimas',
                'small/sq/Jens',
                'small/sq/Robin',
                'small/sq/Thijs',
                'small/sq/Mees',
                'small/sq/Luna'
              ],
              large:[
                'small/rect/Oscar',
                'small/rect/Jake',
                'small/rect/Sophie',
                'small/rect/Dimas',
                'small/rect/Jens',
                'small/rect/Robin',
                'small/rect/Thijs',
                'small/rect/Mees',
                'small/rect/Luna'
              ],
              //               large:[
              //   'small/rect/Oscar.jpg',
              //   'small/rect/Jake.jpg',
              //   'small/rect/Sophie.jpg',
              //   'small/rect/Dimas.jpg',
              //   'small/rect/Jens.jpg',
              //   'small/rect/Robin.jpg',
              //   'small/rect/Thijs.jpg',
              //   'small/rect/Mees.jpg',
              //   'small/rect/Luna.jpg'
              // ],
            },
            title: 'Voorbeelden',
            subtitle: 'Alle achtergronden zijn met alle voorgronden te combineren.'
          },
          carousel:{
            photoArray:{
              small:[
                'small/sq/Luna',
                'small/sq/Luna_binnen1',
                'small/sq/Luna_binnen2',
                'small/sq/Luna_achter'
              ],
              large:[
                'small/rect/Luna',
                'small/rect/Luna_binnen'
              ],
            },
            title: 'Het eindresultaat mag er zijn',
            subtitle: "Je mag zelf bepalen welke teksten op het kaartje komen. Al jullie keuzes en items worden verwerkt in het kaartje. Het eindresultaat is een kaartje dat helemaal op maat is gemaakt!" 
          },
          pricing:{
            title: 'Goed design hoeft niet duur te zijn',
            subtitle: 'Dit is een prijs voorbeeld. Alle oplagen zijn mogelijk, de precieze prijs wordt nog verder uitgelegd op de bestelpagina.'
          }
        }
    }
}
</script>


<style >

.white{
    color:white
}
.black{
    color:black
}


.card-style{
  color:white
}

.green .card-style{
  background-color: rgb(170, 191, 168);
}

.blue .card-style{
  background-color: rgb(60, 84, 122);
}




</style>