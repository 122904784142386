<template>
    <div id="PhotoExplainer" class="bgc ">
        <div class="container-md ">
            <div class="row gy-6 gx-0 py-5">

                <div class="d-flex justify-content-center
                    col-sm
                    col-xl-6 "
                    v-bind:class="orderingPhoto">
                    <img v-bind:src="photo" v-bind:alt="photo"   class="img-fluid" >
                    <!-- <Picture :path="photo" classb="img-fluid"/> -->
                </div>

                <div class="
                    col-sm 
                    col-xl-6
                    align-self-center
                    "
                    v-bind:class="orderingText">
                    <!-- <div class="container"> -->
                        <div class="textblock ">
                            <h3 class="display-6 ">{{title}}</h3>
                            <p class="lead ">{{text}}</p>
                        </div>
                    <!-- </div> -->
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Picture from './Picture.vue'

export default {
    name: 'PhotoExplainer',
    props:['title','text','photoLeft', 'photo'],
    // components:{Picture},
    data(){
        return{

        }
    },
    computed:{
        orderingPhoto(){
            if (this.photoLeft){
                return{
                    'order-last':true,
                    'order-sm-first':true
                }
            } else{
                return{
                    'order-last':true,
                    'order-sm-last':true
                }
            }
        },
        orderingText(){
            return !this.orderingPhoto
        }
    },
    methods:{
    }
}
</script>

<style scoped>
.spacer{
    padding-bottom: 275px;
}
.textblock{
    padding-inline: 15px;
    /* background-color: ivory; */
}

.bgc{
    background-color: rgba(190, 221, 228, 0.486);
    
}

.img-fluid{
    max-height: 450px;
}

</style>