<template>
    <div id="PhotoExplainerV2" class="bgc">
        <div class="container-md">
       
            <div class="card card-style">
                <div class="row g-0">
                    <div class="col-lg-6">
                        <!-- <img :src="photo" class="img-fluid rounded" :alt="photo"> -->
                        <Picture :path="photo" classb="img-fluid rounded"/>
                    </div>
                    <div class="col-lg-6">
                        <div class="card-body ">
                            <h3 class="display-6 card-title px-4 ">{{title}}</h3>
                            <p class="lead card-text pt-3 px-4 ">{{text}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Picture from './Picture.vue'

export default {
    name: 'PhotoExplainerV2',
    props:['title','text','photoLeft', 'photo'],
    components:{Picture},
    data(){
        return{

        }
    },
    computed:{
    },
    methods:{
    }
}
</script>

<style scoped>
.spacer{
    padding-bottom: 275px;
}
.textblock{
    padding-inline: 15px;
    background-color: ivory;
}

.bgc{
    background-color: rgba(190, 221, 228, 0.486);
    
}

/* .card-text{
    font-size: 150%;
    
} */



</style>