<template>
    <div id ='Accordion'>
        <div class="accordion mt-2" :id="id">
            <div v-for="item in items" :key="item.headingId"
                    class="accordion-item"
                    >
                    <h2 class="accordion-header " 
                    :id="item.headingId"
                    >
                    <button class="accordion-button collapsed" 
                        type="button" 
                        data-bs-toggle="collapse" 
                        :data-bs-target="hashTarget(item.collapseId)" 
                        aria-expanded="false" 
                        :aria-controls="item.collapseId">
                        
                        <strong>{{item.title}}</strong>
                    </button>
                    </h2>
                    <div 
                        :id="item.collapseId"
                        class="accordion-collapse collapse " 
                        :aria-labelledby="item.headingId"
                        >
                    <div class="accordion-body">
                        {{item.text}}
                        <img src="small/email.jpg" v-if="item.includeEmail">
                    </div>
                    </div>
                
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name:'Accordion',
    props:['items','id'],
    data(){
        return{
        }
    },
    created(){
        this.initialiseAccordion()
    },
    watch:{
        items : function(){
            this.initialiseAccordion()
        }
    },
    methods:{
        hashTarget(input){
            return '#'+input
        },  

        initialiseAccordion () {
            // console.log('Created Accordion')
            var i=0
            this.items.forEach(item=>{
                i++
                item.collapseId="c"+i
                item.headingId="h"+i
            })
        }
    },
}
</script>

<style scoped>

</style>