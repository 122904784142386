<template>
    <div id="Order">
        <div class="container-sm ">
            <h1 class="display-6">
                Bestellen
            </h1>
            <br>
            
            <p >Om te bestellen stuur je simpelweg een email naar 
                <img src="small/email.jpg">
                We hebben best een hoop informatie van je nodig, gebruik deze pagina als checklist.</p>

            <br>

            <h5>Voorgrond & Achtergrond</h5>

            <p>Kies een voorgrond en een achtergrond uit de voorbeelden hieronder. Je kunt een voor- of achtergrond aanduiden aan de hand van de voorbeeld naam op het ontwerp. Je kiest dus bijvoorbeeld voor voorgrond 'Jake' en achtergrond 'Jens'.</p>
            
            <Carousel
                :photoArray="photoArray"
                :uid="'Voorbeelden2'"
                :window="window"
            />
            
            <h5>Foto's</h5>
            <p>Lever 5-10 duidelijke foto's aan van items die je op het kaartje wilt hebben.</p>
            <br>
            <h5>Teksten</h5>
            <p>Op verschillende plekken op het kaartje komen teksten. Geef duidelijk aan wat je waar wilt hebben.</p>
            <br>
            <h6>Voorkant</h6>
            <p>Hier staat de voornaam! Je hoeft de (voor)naam van je kindje natuurlijk nog niet te verklappen. We gebruiken in het ontwerp een werknaam.</p>
            <br>
            <h6>Binnenkant links</h6>
            <p>Dit is een mooie plek voor een spreuk of een kort gedichtje. Je kunt dit ook leeg laten. Een paar voorbeeldjes:</p>
            <br>
            <em >
                <p>ga de wereld in met verwondering</p>
                <p> laat je verassen door wat je ziet</p>
                <p>bloemen, vogels, zon en regen</p>
                <p>omarm het leven en geniet</p>
            </em>
            <br>
            <em>
                <p>Grote avonturen beginnen klein.</p>    
            </em>
            <br>
            <em >
                <p>Om voor altijd van te houden,</p>
                <p>jij hoort bij ons.</p>
            </em>
            <br>
            <h6>Binnenkant rechts</h6>
            <p>Hier staan in ieder geval (alle) namen van jullie kindje en de geboortedag. Daaronder kun je het tijdstip, gewicht en lengte bij geboorte vermelden. Deze details kun je later doorgeven. Hier staat vaak ook jullie adres en contactgegevens en hoe je bezoek wilt ontvangen in de kraamtijd. Geef ook aan hoe je dat wilt verwoorden. Bijvoorbeeld:</p>
            <br>
            <em >
                <p>Wil je vingertjes en teentjes komen tellen? Dan graag van tevoren even bellen.</p>
            </em>
            <br>
            


            <h6>Achterkant</h6>
            <p>De achterkant laten we meestal leeg, maar als je er iets op wilt kan dat ook!</p>
            <br>
            <h5>Aantal en kosten</h5>
            <p>Geef aan hoeveel kaartjes je wilt bestellen. Het minimum is 50 kaartjes voor 150 euro. Elk extra kaartje kost 1 euro. Voor items in kleur rekenen we een toeslag van 25 euro.</p>
            <br>
            <h5>Extra gegevens</h5>
            <p>Verder willen we graag nog weten wanneer je uitgerekend bent (voor de planning) en jullie adres (op dit adres worden de kaartjes bezorgd).</p>
            <br>
            <h5>En dan?</h5>
            <p>Zodra we je opdracht binnen hebben, controleren we of alle details duidelijk zijn en bepalen we de totale kosten. Vervolgens krijg je een verzoek om te betalen en gaan we aan de slag. Zodra het ontwerp klaar is ontvang je dit in PDF formaat. We kunnen nu nog enkele kleine wijzigingen doen. Als jullie baby geboren wordt, hoef je alleen de details door te geven (naam, gewicht, lengte, datum en tijd) en dan kan de opdracht naar de drukker. Je kunt de kaartjes binnen 2 - 4 werkdagen na het doorgeven van de laatste details verwachten. Je kan vervolgens de kaartjes zelf addresseren en versturen. Zorg dat je voor de geboorte al enveloppen in huis haalt!</p>


        </div>
    </div>
</template>

<script>
import Carousel from './Carousel.vue'

export default {
    name:'Order',
    props:['window'],
    components:{
        Carousel
    },
    data(){
        return{
            photoArray:{
              small:[
                'small/sq/Jake',
                'small/sq/Mees',
                'small/sq/Sophie',
                'small/sq/Dimas',
                'small/sq/Thijs',
                'small/sq/Jens',
                'small/sq/Robin',
                'small/sq/Oscar',
                'small/sq/Luna'
              ],
              large:[
                'small/rect/Jake',
                'small/rect/Mees',
                'small/rect/Sophie',
                'small/rect/Dimas',
                'small/rect/Thijs',
                'small/rect/Jens',
                'small/rect/Robin',
                'small/rect/Oscar',
                'small/rect/Luna'
              ],
            }
        }
    }
}
</script>