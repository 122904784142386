<template>
    <div id='Navbar'>
        <div class="text-center cover-container d-flex h-100 p-3 mx-auto flex-column">
            <header class="masthead mb-auto">
                <div class="inner">
                  <!-- <h3 class="masthead-brand">wonderpost</h3> -->
                  <img src="small/Logo_header2.png" height="36px" class="masthead-brand" alt="sitelogo">
                  <nav class="nav nav-masthead justify-content-center" >
                    <router-link 
                      v-for="page in pages" 
                      v-bind:key="page.renderId" 
                      :class="{'router-link': true,'nav-link': true, active: page.active}" 
                      :to="page.to" 
                      >
                        {{page.name}}
                    </router-link>
                  </nav>
                </div>
            </header>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Navbar',
  data(){
    return{
      // activePage:'Concept',
      pages:[
        {
          active: true,
          to:'/',
          name: 'Concept',
          renderId:100
        },
        {
          active: false,
          to:'/Bestellen',
          name: 'Bestellen',
          renderId:200
        },
        {
          active: false,
          to:'/FAQ',
          name: 'FAQ',
          renderId:300
        }
      ]
    }
  },
  watch:{
    $route : function(){
      // console.log(this.$route.path)
        this.pages.forEach(page=>{
          if (page.to==this.$route.path){
            page.active = true
          } else {
            page.active = false
          }
          page.renderId++
        })
      }
  },
}
</script>

<style scoped>

.cover-container {
  max-width: 80em;
}

.masthead {
  margin-bottom: 2rem;
}

.masthead-brand {
  margin-bottom: 0;
  color:rgba(187, 168, 138, 1);
}

.nav-masthead .nav-link {
  padding: .25rem 0;
  font-weight: 700;
  color: rgba(141, 160, 164, 1);
  background-color: transparent;
  border-bottom: .25rem solid transparent;
}



.nav-masthead .active.nav-link:hover ,
.nav-masthead .active.nav-link:focus {
  border-bottom-color: rgba(187, 168, 138, 0.75);
}

.nav-masthead .nav-link:hover ,
.nav-masthead .nav-link:focus {
  border-bottom-color: rgba(141, 160, 164, 0.75);
}

.nav-masthead .nav-link + .nav-link {
  margin-left: 1rem;
}


.nav-masthead .active {
  color:rgba(187, 168, 138, 1);
  border-bottom-color: rgba(187, 168, 138, 0.5);
}

@media (min-width: 40em) {
  .masthead-brand {
    float: left;
  }
  .nav-masthead {
    float: right;
  }
}

</style>