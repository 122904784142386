import Vue from 'vue'
import Router from 'vue-router'
// import Navbar from '@/components/Navbar'
import Home from '@/components/Home'
import Order from '@/components/Order'
import FAQ from '@/components/FAQ'
import Test from '@/components/Test'

// import firebase from 'firebase'


Vue.use(Router)

let router = new Router({
    //scrollBehavior (to, from, savedPosition) {
    scrollBehavior () {
        return { x: 0, y: 0 }
      },
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/bestellen',
            name: 'bestellen',
            component: Order,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/FAQ',
            name: 'FAQ',
            component: FAQ,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/test',
            name: 'test',
            component: Test,
            meta: {
                requiresAuth: false
            }
        }
    ]
});


//Nav guards

// router.beforeEach((to, from, next)=>{
//     //check for requiredAuth guard
//     if(to.matched.some(record => record.meta.requiresAuth)) {
//         //Check if NOT logged in
//         if(!firebase.auth().currentUser) {
//             //Go to login
//             next({
//                 path: '/login',
//                 query : {
//                     redirect: to.fullPath
//                 }
//             })
//         } else {
//             //Proceed to route
//             next()
//         }
//     } else if (to.matched.some(record=> record.meta.requiresGuest)){
//         //Check if  logged in
//         if(firebase.auth().currentUser) {
//             //Go to login
//             next({
//                 path: '/',
//                 query : {
//                     redirect: to.fullPath
//                 }
//             })
//         } else {
//             //Proceed to route
//             next()
//         }
//     } else {
//         //Proceed to route
//             next()
//     }
// })

export default router