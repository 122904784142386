<template>
    <div id="ScrollHero">
        <div class="text-center bgimg" v-bind:class="{avif : avifSupported, jpg : !avifSupported}">
            
            <div class="container ">    
                <h1 class="title white display-3">{{title}}</h1>
                <p class="lead white">{{text}}</p>
            </div> 
        </div>
    </div>
</template>

<script>    
    export default {
        name:'ScrollHero',
        props:['title','text','image'],
        data(){
            return{
                avifSupported:true
            }
        },
        created(){
            var avif = new Image();
            avif.src =
            "data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=";
            avif.onload = () => {
                console.log("avif");
                this.avifSupported = true
            };
            avif.onerror = () => {
                console.log("no-avif")
                this.avifSupported = false
            }
            console.log(avif.onload)
        }
    }
</script>

<style scoped>
.title{
    padding-top:30px;
    
}

.lead{
    white-space: pre-wrap;
}

.white{
    color: white
}

.jpg{
    background : url('/small/cover4.jpg') no-repeat;
}

.avif{
    background : url('/small/cover4.avif') no-repeat;
}

.bgimg{
    background-position : 50% 50%;
    height : 800px;
    background-size : cover;
}

@media (max-width: 1475px) { 

    .jpg{
        background : url('/small/cover4small.jpg') no-repeat;
    }

    .avif{
        background : url('/small/cover4small.avif') no-repeat;
    }

    .bgimg{
        background-position : 52% 50%;
        height : 700px;
        width:100%;
        background-size : cover;
    }

}

</style>