import { render, staticRenderFns } from "./ScrollHeroV2.vue?vue&type=template&id=1064a190&scoped=true&"
import script from "./ScrollHeroV2.vue?vue&type=script&lang=js&"
export * from "./ScrollHeroV2.vue?vue&type=script&lang=js&"
import style0 from "./ScrollHeroV2.vue?vue&type=style&index=0&id=1064a190&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1064a190",
  null
  
)

export default component.exports