<template>
    <div id='Title'  :style="bgc(bgColor)">
        <div class='container-xxl'>
            <h1 class="display-6 text-center pt-5 pb-3">{{title}}</h1>
            <p v-if="subtitle" class="lead text-center pb-3">{{subtitle}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Title',
    props: ['title','subtitle','bgColor'],
    data(){
        return{}
    },
    methods:{
        bgc(){
            // console.log('bgc')
            if (this.bgColor=='blue'){
                return {'background-color': 'rgba(190, 221, 228, 0.486)'}
            } else if (this.bgColor=='yellow'){
                return {'background-color': 'rgba(200, 160, 100, 0.3)'}
            } else if (this.bgColor=='white'){
                return {'background-color': 'rgba(0, 0, 0, 0)'}
            }  
        }
    }
}
</script>

<style>

h1{
    margin-block-end:0px
}

p{
    margin-block-end:0px
}

</style>