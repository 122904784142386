<template>
    <div id="FAQ">
        <div class="container-sm ">
            <h1 class="display-6">
                FAQ
            </h1>
            <br>

            <Accordion
                :items="items"
                :id="'FAQ'"
            />


        </div>
    </div>
</template>

<script>
import Accordion from './Accordion.vue'

export default {
    name:'FAQ',
    props:['window'],
    components:{
        Accordion
    },
    data(){
        return{
            items:[
                {
                    title:'Wanneer moet ik de opdracht uiterlijk aanleveren?',
                    text:'De opdracht moet 3 weken voor de geboorte binnen zijn. Maar let op! Je baby kan (best veel) eerder geboren worden dan de uitgerekende datum en de laatste weken kun je ook wel iets anders aan je hoofd hebben. Als je dus veilig wilt zitten, probeer dan de opdracht al in week 26 van je zwangerschap te plaatsen.'
                },
                {
                    title:'Wat is het formaat van de kaartjes?',
                    text:'De standaard maat die we aanhouden is 148x148 mm. Op deze maat passen alle ontwerpen mooi. Heb je toch een voorkeur voor een andere maat, neem dan contact op voor de mogelijkheden.'
                },
                {
                    title:'Waar kan ik enveloppen kopen?',
                    text:'Waar je wilt, maar we kunnen enveloppenland.nl aanraden. De envelopmaat die je nodig hebt is 155x155 mm.'
                },
                {
                    title:'Op welk papier worden de kaartjes gedrukt?',
                    text:'We gebruiken een papiersoort met een matte uitstraling van 350 gr/m2. Het papier is FSC gecertificeerd. Heb je een andere voorkeur? Neem dan contact op voor de mogelijkheden.'
                },
                {
                    title:'Kunnen jullie de kaartjes ook direct naar de ontvangers versturen?',
                    text:'Nee, helaas kan dit (nog) niet. Je ontvangt de kaartjes als een pakketje en moet ze vervolgens zelf verzenden naar je ontvangers.'
                },
                {
                    title:'Wat zijn de verzendkosten?',
                    text:'De verzendkosten zijn bij alle genoemde prijzen inbegrepen.'
                },
                {
                    title:'Wat is de levertijd?',
                    text:'Je kunt de kaartjes binnen 2 - 4 werkdagen na het doorgeven van de laatste details (na de geboorte) verwachten.'
                },
                {
                    title:'Wanneer krijg ik het ontwerp?',
                    text:'Uiterlijk 3 weken na het plaatsen van de opdracht, krijg je het ontwerp in PDF formaat toegestuurd.'
                },
                {
                    title:'Zijn de enveloppen en postzegels inbegrepen?',
                    text:'Nee, je moet zelf enveloppen en postzegels regelen. Het is handig dit voor de geboorte te doen en de enveloppen ook al te adresseren. Dan hoef je als je de kaartjes binnenkrijgt ze alleen nog in de enveloppen te stoppen en op de bus te doen.'
                },
                {
                    title:'Kan ik de kaartjes retourneren?',
                    text:'Omdat de kaartjes volledig gepersonaliseerd zijn naar jullie wensen, zijn ze uitgesloten voor het herroepingsrecht en kun je ze dus niet terug sturen. Controleer het ontwerp (dat je in PDF toegestuurd krijgt) dus goed!'
                },
                {
                    title:'Help! Er is iets niet goed aan mijn kaartjes. Wat moet ik doen?',
                    text:'Neem zo snel mogelijk contact op. We zoeken dan samen naar een oplossing. We zijn bereikbaar via ',
                    includeEmail:true
                },
                {
                    title:'Hoe kan ik jullie bereiken?',
                    text:'We zijn bereikbaar via ',
                    includeEmail:true
                },
                {
                    title:'Ik heb een idee dat niet tussen de ontwerpen staat. Maak je ook nieuwe designs op opdracht?',
                    text:'In overleg is er veel mogelijk! Neem even contact op, dan kijken we wat er mogelijk is. We zijn bereikbaar via ',
                    includeEmail:true
                },
            ]
        }
    }
}
</script>