// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAKJuCPoKL-DArpnSyQvJ87H-zO9P95Aq8",
  authDomain: "wonderpostnl.firebaseapp.com",
  projectId: "wonderpostnl",
  storageBucket: "wonderpostnl.appspot.com",
  messagingSenderId: "135468505292",
  appId: "1:135468505292:web:2b659c4fb3fcb48048ca69",
  measurementId: "G-X7C2EB0NNC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default {app, analytics}