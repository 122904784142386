<template>
    <div id='Carousel'>     
        <div v-bind:id="uid" class="carousel carousel-dark slide"  data-bs-interval="false" >
            <!--data-bs-ride="carousel"  -->
            <div class="carousel-indicators" >
                <button type="button" v-bind:key="photo.id" v-for="photo in photoObjects" 
                    v-bind:data-bs-target="hashUid" 
                    v-bind:data-bs-slide-to="photo.id" 
                    v-bind:aria-label="'Slide ' + photo.id" 
                    v-bind:class="{active: photo.active}" 
                    v-bind:aria-current="photo.active" ></button>
            </div>        
            <div class="carousel-inner">
                <div v-bind:key="photo.id" v-for="photo in photoObjects" class="carousel-item" v-bind:class="{active: photo.active}">
                    <!-- <img v-bind:src="photo.photo" class="d-block w-100" v-bind:alt="photo.photo"> -->
                    <Picture :path="photo.photo" classb="d-block w-100"/>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" v-bind:data-bs-target="hashUid" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" v-bind:data-bs-target="hashUid" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
        <div class="spacer"></div>
        
    </div>
</template>

<script>
import Picture from './Picture.vue'

export default {
    name: 'Carousel',
    props: ['photoArray', 'uid','window'],
    components:{Picture},
    data(){
        return {
            // photoObjects:[]
        }
    },
    computed : {
        hashUid(){
            return "#"+this.uid
        },
        photoObjects(){
            var selectedArray =[]
            // console.log(this.window.width)
            if (this.window.width>800){
                selectedArray=this.photoArray.large
            } else{
                selectedArray=this.photoArray.small
            }
            // console.log('selectedArray')
            // console.log(selectedArray)
            var i=0
            var photoObjects=[]
            selectedArray.forEach(photo=>{
                photoObjects.push({
                    id: i,
                    photo: photo,
                    active: (i == 0) ? true : false
                })
                i++
            })
            return photoObjects
        }
    },
    created(){

        // var selectedArray =[]
        // console.log(this.window.width)
        // if (this.window.width>1500){
        //     selectedArray=this.photoArray.large
        // } else{
        //     selectedArray=this.photoArray.small
        // }
        // console.log('selectedArray')
        // console.log(selectedArray)
        // var i=0
        // selectedArray.forEach(photo=>{
        //     this.photoObjects.push({
        //         id: i,
        //         photo: photo,
        //         active: (i == 1) ? true : false
        //     })
        //     i++
        // })
    }
}
</script>

<style>
.spacer{
    padding-bottom: 25px;
}

</style>